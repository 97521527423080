import {
  H2_SERVICES_IMG01,
  H2_SERVICES_IMG02,
  H2_SERVICES_IMG03,
  H2_SERVICES_IMG04,
  H4_SERVICES_IMG01,
  H4_SERVICES_IMG02,
  H4_SERVICES_IMG03,
  H4_SERVICES_IMG04,
  SERVICES_IMG01,
  SERVICES_IMG02,
  SERVICES_IMG03,
  SERVICES_IMG04,
  SERVICES_ITEM_SHAPE,
} from "../lib/assets";

export const SERVICES_TWO_LIST = [
  {
    id: 1,
    src: H2_SERVICES_IMG01,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-piggy-bank",
    href: "/services-details",
    title: "Compliance 1",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
  {
    id: 2,
    src: H2_SERVICES_IMG02,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-calculator",
    href: "/services-details",
    title: "Compliance 2",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
  {
    id: 3,
    src: H2_SERVICES_IMG03,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-money",
    href: "/services-details",
    title: "Govenance",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
  {
    id: 4,
    src: H2_SERVICES_IMG04,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-layers",
    href: "/services-details",
    title: "Risk Management",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
];

export const SERVICES_THREE_LIST = [
  {
    id: 1,
    src: H4_SERVICES_IMG01,
    icon: "flaticon-healthcare",
    href: "/services-details",
    title: "Health Insurance",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
  {
    id: 2,
    src: H4_SERVICES_IMG02,
    icon: "flaticon-protection",
    href: "/services-details",
    title: "Vehicle Insurance",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
  {
    id: 3,
    src: H4_SERVICES_IMG03,
    icon: "flaticon-ship",
    href: "/services-details",
    title: "Cargo Insurance",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
  {
    id: 4,
    src: H4_SERVICES_IMG04,
    icon: "flaticon-house",
    href: "/services-details",
    title: "Fire Insurance",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
];

export const SERVICES_FOUR_LIST = [
  {
    id: 1,
    icon: "flaticon-business-presentation",
    href: "/services-details",
    title: "Marketing Strategy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 2,
    icon: "flaticon-rocket",
    href: "/services-details",
    title: "SEO Consultancy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 3,
    icon: "flaticon-mission",
    href: "/services-details",
    title: "Target Audience",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
];


export const SERVICES_ONE_LIST = [
  {
    id: 1,
    icon: "flaticon-briefcase",
    href: "/services-details",
    src: SERVICES_IMG01,
    title: "Compliance Risk Assessment",
    subtitle: "Identify and mitigate compliance risks effectively.",
  },
  {
    id: 2,
    icon: "flaticon-taxes",
    href: "/services-details",
    src: SERVICES_IMG02,
    title: "Policy and Procedure Development",
    subtitle: "Develop robust policies and procedures to ensure compliance.",
  },
  {
    id: 3,
    icon: "flaticon-money",
    href: "/services-details",
    src: SERVICES_IMG03,
    title: "Training and Education",
    subtitle: "Equip your team with the knowledge to stay compliant.",
  },
  {
    id: 4,
    icon: "flaticon-taxes",
    href: "/services-details",
    src: SERVICES_IMG04,
    title: "Ongoing Monitoring and Reporting",
    subtitle: "Continuously monitor and report compliance status.",
  },
];
