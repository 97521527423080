import React from "react";

export const ContactAreaMap = () => {
  return (
    <div className="contact-map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31754.122460554103!2d-0.3686217198236892!3d5.818156871447303!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf0c71326ec2b1%3A0xd5587c8564ab39be!2sNsawam!5e0!3m2!1sen!2sgh!4v1718116782432!5m2!1sen!2sgh"
        allowfullscreen
        loading="lazy"
        title="contact map"
      ></iframe>


    </div>
  );
};
