import {
  BRAND_IMG01,
  BRAND_IMG02,
  BRAND_IMG03,
  BRAND_IMG04,
  BRAND_IMG05,
} from "../lib/assets";

export const BRAND_LIST = [
  { id: 1, src: BRAND_IMG01 },
  { id: 2, src: BRAND_IMG02 },
  { id: 3, src: BRAND_IMG03 },
  { id: 4, src: BRAND_IMG04 },
  { id: 5, src: BRAND_IMG05 },
  { id: 6, src: BRAND_IMG03 },
];
